import React, { Component } from 'react';
import Typist from 'react-typist';
import './App.css';
import ImageSlider from './components/ImageSlider';

class App extends Component {
  render() {
    return (
      <div>
        <ImageSlider />
        <div className='overlay'></div>
        <div className='container'>
          <div className='text'>
            <Typist className='name'>
              <h2>Hi! My name is Mercy Oseni</h2>
              <Typist.Backspace count={30} delay={2000} />
              {/* just trying to repeat */}
              <Typist.Delay ms={500} />
              <h2>Hi! My name is Mercy Oseni</h2>
            </Typist>

            <Typist>
              <p>I am a Software Engineer in Lagos, Nigeria. I have experience working with Ruby/Ruby-on-Rails, HTML, CSS and Javascript.</p>
              <p>I have knowledgeable experience working with Node and ReactJS.</p>
            </Typist>

            <Typist>
              <p>I am also an AI/ML enthusiast.</p>
              <Typist.Delay ms={3000} />
              <p>Thanks for visiting!</p>
              <Typist.Backspace count={20} delay={10000} />
            </Typist>

            <div className='contact-icons'>
              <div className='div-icon twitter'>
                <a href='https://twitter.com/mehrceey04' className='icons' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-twitter'></i>
                </a>
              </div>
              <div className='div-icon instagram'>
                <a href='https://instagram.com/mehrceey04' className='icons' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-instagram'></i>
                </a>
              </div>
              <div className='div-icon facebook'>
                <a href='https://facebook.com/mehrceey04' className='icons' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-facebook'></i>
                </a>
              </div>
              <div className='div-icon github'>
                <a href='https://github.com/mercyoseni' className='icons' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-github'></i>
                </a>
              </div>
              <div className='div-icon linkedin'>
                <a href='https://linkedin.com/in/halimat-mercy-oseni/' className='icons' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-linkedin'></i>
                </a>
              </div>
              <div className='div-icon envelope'>
                <a href='mailto:info@mercyoseni.com' className='icons'>
                  <i className='fa fa-envelope'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
