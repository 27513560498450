import React from 'react';
import { Fade } from 'react-slideshow-image';

const images = [
  'https://firebasestorage.googleapis.com/v0/b/mercyoseni-76f6c.appspot.com/o/IMG_0016.jpg?alt=media&token=cfe86b17-b260-47a8-b419-5f3fd42dfcb5',
  'https://firebasestorage.googleapis.com/v0/b/mercyoseni-76f6c.appspot.com/o/me%20copy.jpg?alt=media&token=b775e140-8822-4d53-bec1-2aa4ba1f0a42',
  'https://firebasestorage.googleapis.com/v0/b/mercyoseni-76f6c.appspot.com/o/POTW2018-05-15-73.jpg?alt=media&token=d4279567-c64f-4fb0-918e-eb1df0851233',
  ]

const fadeProperties = {
  duration: 3000,
  transitionDuration: 2000,
  arrows: false,
}

const Slideshow = () => {
  return (
    <Fade {...fadeProperties}>
      {
        images.map((image, index) =>
          <img key={index} style={{width: '100%', height: '100vh'}} src={image} alt={'slide-images'}/>
        )
      }
    </Fade>
  )
}

export default Slideshow;
